import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { Link } from "gatsby"

export default function Product({ data, pageContext }) {
const name = data.productdata.productName;
const product = data.productdata;
const seodescription = product.fitting + " " + product.power + " " + product.productSubcategory + " heater";

return (
    <Layout>
    
      <SEO
        title={name}
        description={seodescription}
      />

<Hero heading={name} subheading={seodescription} image={product.productImage} />

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">{seodescription}. <br/> £{product.productPrice} from {product.retailer}</h3>
<p className="text-gray-600 mb-8">{product.description}.</p>
<p className="text-gray-600 mb-8">
  <ul>
    <li>Fuel type: {product.powersource}</li>
    <li>Fuel detail: {product.productSubcategory}</li>
    <li>Fitting: {product.fitting}</li>
    <li>Design / format: {product.design}</li>
    <li>Power: {product.power}</li>
    <li>Price: £{product.productPrice} (check for latest price)</li>
  </ul>
</p>
<p className="text-gray-600 mb-8"><Link class="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded" to={product.productUrl}>Buy it at {product.retailer}</Link></p>
</div>
</div>

    </Layout>
)
}


export const query = graphql`
  query($name: String!) {
    productdata: googleSheetProductsRow(productName: {eq: $name}) {
              productCategory
              power
              powersource
              fitting
              description
              productImage
              design
              productName
              retailer
              productPrice
              productSubcategory
              productUrl
      }
  }
`